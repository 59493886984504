import Header from "./component/Header";
import { Routes, Route } from "react-router-dom";

import Home from "./pages/Home";
import BriseStake from "./pages/BriseStake";
import Exchange from "./pages/Exchange";

function App() {

  return (
    <Routes>
      <Route
        exact
        path="/"
        element={
          <>
            <Header />
            <Home />
          </>
        }
      />
      <Route
        exact
        path="/briseStake"
        element={
          <>
            <Header />
            <BriseStake />
          </>
        }
      />
      <Route
        exact
        path="/Exchange"
        element={
          <>
            <Header />
            <Exchange />
          </>
        }
      />
      {/* <Route
        exact
        path="/AddLiquidity"
        element={
          <>
            <Header
              isConnected={isConnected}
              connect={connect}
              account={account}
              disconnect={disconnect}
            />
            <AddLiquidity
              isConnected={isConnected}
              connect={connect}
              account={account}
            />
          </>
        }
      /> */}

      {/* <Route exact path="/" component={Home} />
          <Route path="/Liquidity" component={Liquidity} />
          <Route path="/Exchange" component={Exchange} />
          <Route path="/AddLiquidity" component={AddLiquidity} /> */}
    </Routes>
  );
}

export default App;
